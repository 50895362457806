<template>
  <v-container fluid :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-0'">

    <v-toolbar dense flat>
      <v-btn-toggle
        v-model="toggle_multiple"
        multiple
        dense
        tile
        borderless
        color="primary accent-3"
      >
        <v-btn
          v-model="search.today"
          text
          class="px-1"
          @click="btnClick('today')"
        >
          <v-icon
            small
            :color="search.today ? 'primary' : ''"
          >mdi-calendar</v-icon>
          <span>오늘</span>
        </v-btn>
      </v-btn-toggle>
      <!-- [정렬] 가나다순 -->
      <div class="ml-2 hidden-xs-only" style="width:150px">
        <v-select
          v-model="sort.default"
          :items="select.defaultSort"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectOrder"
        ></v-select>
      </div>
      <!-- [검색] 재직여부 -->
      <div class="hidden-xs-only" style="width:120px">
        <v-select
          v-model="search.ss1"
          :items="select.ss1"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss1')"
        ></v-select>
      </div>

      <v-spacer></v-spacer>

      <div style="width: 100px" class="mx-3 hidden-xs-only">
        <v-select
          v-model="search.sf"
          :items="select.sf"
          label="검색대상"
          item-text="text"
          item-value="value"
          class="mt-8"
          dense
          style="font-size: 0.785rem !important"
        ></v-select>
      </div>
      <v-text-field
        v-model="search.sw"
        label="검색"
        append-icon="mdi-magnify"
        clearble
        class="mt-8 hidden-xs-only"
        @keyup.enter="searchPopBtn"
        @click:append="searchPopIcon"
      ></v-text-field>

      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
    </v-toolbar>

    <v-toolbar dense flat color="grey lighten-2">
      <div class="text-lg-left">
        <v-chip small color="primary">
          {{ totalItems }}
        </v-chip>
        <v-btn text icon
          @click="initVals"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-refresh</v-icon>
            </template>
            <span>초기화</span>
          </v-tooltip>
        </v-btn>
      </div>
      <div class="text-left hidden-xs-only">
        <v-chip
          v-for="(item, i) in searchKeywords"
          :key="i"
          small
          :close="!item.isEver"
          color="grey lighten-4"
          class="mr-1"
          @click:close="closeSearchKeyword(item)"
        >{{ item.text }}</v-chip>
      </div>

      <v-spacer></v-spacer>

      <div style="width: 60px">
        <v-select
          :items="itemsPerPageOptions"
          v-model="options.itemsPerPage"
          dense
          class="mt-6"
        ></v-select>
      </div>
    </v-toolbar>

    <v-card :elevation="0">
      <v-data-table
        :headers="headers"
        :items="staffs"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-header
        hide-default-footer
        no-data-text="데이터가 없습니다"
        no-results-text="검색결과가 없습니다"
        loading-text="로딩중..."
      >
        <template v-slot:[`item.id`]="{ item }">
          <v-list class="pa-0 ma-0">
            <v-list-item class="float-left">
              <v-list-item-content>
                <v-list-item-title class="text-body-2 ma-1">
                  <!-- <a @click="viewStaffInfo(item.id)">
                    <span class="text-body-2 font-weight-bold primary--text mr-1">{{ item.name }}</span>
                  </a> -->
                  <span
                    :class="item.status ? 'primary--text' : 'grey--text text--lighten-1'"
                    style="cursor:pointer;font-size: 0.975rem;font-weight: 550;"
                    @click="viewStaffInfo(item.id)"
                  >
                    <v-icon
                      class="mr-1 mb-1"
                      :color="levelColors[item.lv]"
                      style="font-size: 1.25rem;font-weight: 550;"
                    >
                      {{ levelIcons[item.lv] }}
                    </v-icon>
                    {{ item.name }}
                  </span>
                  <span class="text-body-2 mx-1">{{ item.email }}</span>
                  <span class="text-body-2 mx-1">
                    {{ (item.rank && item.position) ? `${item.rank}|${item.position}` : item.rank || item.position }}
                    {{ item.code ? `(${item.code})` : '' }}
                    {{ item.birthday ? item.isLunar ? '(-)' : '(+)' : '' }}{{ strDateFormat(item.birthday) }}
                  </span>
                  <span class="text-body-2 mx-1" v-html="item.view1"></span>
                  <span
                    v-if="item.status"
                    class="text-body-2 primary--text ml-1"
                  >[재직]</span>
                  <span
                    v-else
                    class="text-body-2 grey--text ml-1"
                  >[퇴직]</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <!-- 외부 페이지네이션 -->
      <div class="text-center py-5">
        <v-pagination
          v-model="options.page"
          :length="pages"
        ></v-pagination>
      </div>
    </v-card>

  </v-container>
</template>

<script>
import sleep from '@/lib/sleep'

// 구분: filters
import strDateFormat from '@/filters/strDateFormat'

// @: pdfmake list
import { pdfMake, pdfListStyle } from '@/lib/pdfmakeList'

export default {
  components: {
  },

  data: () => ({
    // 구분: 유저레벨 색상
    levelTitles: {
      M: '관리자',
      G: '관리대행자',
      U: '공유유저'
    },
    // 구분: 유저레벨 색상
    levelIcons: {
      M: 'mdi-account-cog', // 관리자
      G: 'mdi-account-cog-outline', // 관리대행자
      U: 'mdi-account' // 일반유저
    },
    // 구분: 유저레벨 색상
    levelColors: {
      M: 'error', // 관리자
      G: 'success', // 관리대행자
      U: 'primary' // 일반유저
    },
    // 구분: 꼼수 - 헤더스에 별로 의미없는 1개만 매칭시킨다.
    headers: [
      {
        text: '',
        value: 'id',
        align: 'left',
        sortable: false
      }
    ],
    // 구분: 게시판용 변수들
    staffs: [],
    totalItems: 0,
    itemsPerPageOptions: [ 15, 30, 50, 100 ],
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체
    params: { // 검색용 인자 객체
      draw: 0,
      where: {},
      sort: [ 'name' ], // 주의: 정렬의 갯수만큼 초기값 지정해야 함
      order: [ 'ASC' ],
      offset: 0,
      limit: 1
    },
    // 구분: 초기값 설정이 필요한 변수들
    options: { // v-data-table 에 의해 자동으로 기본값이 들어있는 객체.
      itemsPerPage: 15, // 초기값을 지정했다. 기본값은 15라 매뉴얼에 나옴
      page: 1
    },
    toggle_multiple: [], // v-btn-toggle 의 초깃값이 없는 상태
    loading: false,
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    search: {
      sf: 1, // 검색어 검색 select 의 선택된 필드값
      sw: '', // 검색어 검색 text box input 값
      today: false, // 오늘 검색 버튼 클릭값 - true/false
      ss1: '' // [재직여부] 셀렉트 검색 1 선택값
    },
    // 구분: 정렬에 필요한 변수들 - 초기화 필요
    sort: {
      default: 1 // [가나다순]기본정렬 매칭 - 정렬의 기본값은 select.sort.value 로 결정
    },
    // 구분: 셀렉트 객체들
    select: {
      sf: [ // 검색어 검색 필드 1 셀렉트
        { text: '이름', value: 1 },
        { text: '사원번호', value: 2 },
        { text: '직위', value: 9 },
        { text: '자격', value: 10 },
        { text: '출생년도', value: 4 },
        { text: '생일(월)', value: 11 },
        { text: '주소', value: 3 },
        { text: '취미', value: 5 },
        { text: '학교|전공', value: 6 },
        { text: '면허', value: 7 },
        { text: '경력', value: 8 }
      ],
      ss1: [ // 재직여부 셀렉트
        { text: '재직여부', value: '' },
        { text: '재직', value: 'on' },
        { text: '퇴직', value: 'off' }
      ],
      defaultSort: [ // 기본 정렬 셀렉트 - order 가 매칭되어있다.
        { text: '가나다순', value: 1, field: 'name', order: 'ASC' },
        { text: '공유일순', value: 2, field: 'createdAt', order: 'DESC' },
        { text: '사원번호순', value: 3, field: 'code', order: 'ASC' }
      ]
    },
    // 구분: 검색어 칩을 사용하는 것들의 배열 - 검색어 칩을 사용하는 요소를 명확히 알 수있다.
    // 타입: 정렬/검색 - sort/select
    // 이름: 요소이름 - sort.defalut
    // select: 비어있지 않은 경우 해당 셀렉트를 이용한다.
    // showSelect: 검색어 검색처럼 셀렉트를 직접 사용하진 않지만 텍스트를 보여줄 경우 사용(select: '' 인 경우에 주로 사용)
    // isEver: true 인 경우 항시 보여야 한다.
    // loading: true 인 경우 로딩시 처리된다
    useSearchKeywords: [
      { type: 'sort', name: 'default', select: 'defaultSort', showSelect: '', isEver: true, loading: true },
      { type: 'search', name: 'ss1', select: 'ss1', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'sw', select: '', showSelect: '', isEver: false, loading: false }
    ],
    // 구분: 검색어 칩 배열을 위한 변수
    searchKeywords: [],
    // 구분: 기타 변수들
    timeout: null,
    // 구분: pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 20, 20, 20, 20 ],
      content: []
    }
  }),

  computed: {
    setOffset () {
      if (!this.options.page) return 0
      return (this.options.page - 1) * this.options.itemsPerPage
    },
    setLimit () {
      return this.options.itemsPerPage
    },
    pages () { // 페이지네이션 객체에 쓰인다
      if (this.options.itemsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.options.itemsPerPage)
    }
  },

  watch: {
    options: {
      handler () {
        this.list()
      },
      deep: true
    },
    // 주의:사용안함:[2021.10.1]자동으로 검색되지 않게 수정
    // 'search.sw': { // 검색어 자동 감지
    //   handler () {
    //     this.searchWordDelay()
    //   }
    // },
    'options.itemsPerPage': { // 페이징 갯수 변경 자동감지
      handler () {
        this.options.page = 1 // 1페이지로 이동
        this.params.offset = 0 // 옵셋을 초기화 하지 않으면 에러
        this.delay(50)
      }
    }
  },

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    // 중요: 로딩시 등장해야할 검색어 칩을 찾아서 띄운다. 전돨되는 값은 배열이다.
    this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))
  },

  methods: {
    strDateFormat,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    initialize () {
      this.list()
    },
    // 참고: watch 로 검색시 약간의 딜레이를 줘야 한다.
    delay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.list()
      }, ms)
    },
    // 검색어 검색시 딜레이 주는 함수
    searchWordDelay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.searchWord()
      }, ms)
    },
    // 중요: 등록시 검색등에 쓰이는 변수 초기화하고 리스트 재패칭
    async initVals () {
      this.doInit().then(() => {
        this.delay(100)
      })
    },
    // 변수 초기화 실행
    doInit () {
      return new Promise((resolve, reject) => {
        // 구분: params 로 백앤드로 전송되는 값
        this.params.draw = 0
        this.params.where = {}
        this.params.sort = [ 'name' ]
        this.params.order = [ 'ASC' ]
        this.params.offset = 0
        this.params.limit = 1

        // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
        this.search.sf = 1 // 검색어 검색 의 선택된 필드값
        this.search.sw = '' // 검색어 검색의 text box input 값
        this.search.today = false // 오늘 검색 버튼 클릭값 - true/false
        this.search.ss1 = '' // 재직여부 셀렉트 선택값

        // 구분: 정렬 기본값 매칭(갯수만큼)
        this.sort.default = 1

        // 구분: 기타 초기값 설정이 필요한 변수들
        this.items = []
        this.totalItems = 0
        this.options.itemsPerPage = 15
        this.options.page = 1
        this.toggle_multiple = []
        this.loading = false

        // 구분: 검색어 칩 배열도 초기화
        this.searchKeywords = []
        // 그리고 초기 검색어 칩을 다시 띄움
        this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))

        resolve(true)
      })
    },
    // !! 리스트 패칭
    async list () {
      try {
        if (this.loading) return
        this.loading = true

        this.params.draw++
        this.params.offset = this.setOffset
        this.params.limit = this.setLimit

        // !! 검색용 객체 만들기 - where 의 값이 없으면 삭제한다.
        const ws = this.params.where
        for (let key in ws) {
          if (!ws[key]) {
            delete ws[key]
          }
        }
        // console.log(this.params)

        // !! 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(500 - Math.floor(Math.random() * 300))

        const { data } = await this.$axios.get('admin/staff/allList', { params: this.params })
        if (!data.success) throw new Error(`list error: ${data.message}`)

        // 총 검색 갯수(이게 주어져야 페이징이 된다)
        this.totalItems = data.totalItems

        // 참고: 데이터 리스트 순회하며 보여줄 데이터 생성
        await data.list.map(d => {
          d.view1 = ''
          if (d.teams && d.teams.length > 0) {
            let teamView = []
            d.teams.forEach(team => {
              if (team.name !== '회의실관리팀') { // !![2021.7.27] 회의실관리팀 제거(차후 복구)
                const colors = team.color.split(' ')
                const color2 = colors.length === 2 ? ` text--${colors[1]}` : ''
                const txtColor = `${colors[0]}--text${color2}`
                teamView.push(`<span class="${txtColor}">${team.name}</span>`)
              }
            })
            d.view1 = teamView.join(' | ')
          }
        })

        // 참고: 데이터 반영
        this.staffs = data.list

        this.loading = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 상세페이지 가기 - id로 변경
    async viewStaffInfo (id) {
      try {
        this.redirect(`/admin/viewStaffInfo/${id}`)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // async viewStaffInfo (email) {
    //   try {
    //     this.redirect(`/admin/viewStaffInfo/${email}`)
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: -- 검색처리 메소드 모음
    // 팝업 검색 버튼 클릭 이벤트 핸들러
    async searchPopBtn () {
      try {
        // 검색어 검색 처리
        await this.searchWord()
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[2021.10.1]팝업검색시 돋보기 아이콘을 클릭할때 검색처리 - 검색어가 없으면 아무것도 안함
    async searchPopIcon () {
      try {
        // 사용안함:검색어가 있는 경우만 searchPopBtn() 를 실행
        // if (this.search.sw) {
        //   await this.searchPopBtn()
        // }
        await this.searchPopBtn()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - true/false 검색 버튼 처리 함수
    async btnClick (elem) {
      try {
        this.search[elem] = !this.search[elem]
        this.params.where[elem] = this.search[elem]

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 셀렉트 검색 처리 메소드
    async selectChange (elem) {
      try {
        this.params.where[elem] = this.search[elem]

        // !! 검색어 칩 처리 - 타입은 검색 - search && name = elem
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === elem)
        await this.setSearchKeywords(kw)

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 검색어 검색 처리 함수
    async searchWord () {
      try {
        if (this.search.sw.length > 0) { // 검색어가 있으면 파라미터에 넣고
          this.params.where.sf = this.search.sf
          this.params.where.sw = this.search.sw
        } else { // 없어도 일단 넣지만 값을 비운다. list() 에서 알아서 삭제된다.
          this.params.where.sf = ''
          this.params.where.sw = ''
        }

        // !! 검색어 칩 처리 - type = search  && name = sw
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === 'sw')
        await this.setSearchKeywords(kw)

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 정렬처리 메소드 모음 ----
    // this.select.{가변이름}Sort 로 여러개의 소트를 처리할 수 있다.
    // 여러개의 소트가 있어도 처리 가능하다
    // this.params.sort 를 초기화 하고 모든 소트를 새로 만든다.
    async selectOrder () {
      try {
        // 초기화
        this.params.sort = []
        this.params.order = []

        for (let key in this.sort) {
          const selectSortValue = this.sort[key]
          const field = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].field
          const order = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].order

          this.params.sort.push(field)
          this.params.order.push(order)

          // 검색어 칩 - type = sort(정렬)  && name = elem
          const kw = this.useSearchKeywords.find(k => k.type === 'sort' && k.name === key)
          await this.setSearchKeywords(kw)
        }

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색어칩 처리 메소드 모음
    // 로딩시 보여줄 검색어 칩을 처리하는 메서드
    async loadSearchKeywords (kw) {
      try {
        if (!Array.isArray(kw)) throw new Error('잘못된 변수전달 방식입니다.')
        kw.forEach(async (k) => {
          await this.setSearchKeywords(k)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 검색어 칩을 보여주는 처리를 하는 메서드
    async setSearchKeywords (kw) {
      try {
        // this.searchKeywords 배열에 등록될 객체의 뼈대
        let skw = { text: '', type: kw.type, name: kw.name, isEver: kw.isEver }

        // 기존 같은 타입과 이름의 배열이 있으면 삭제
        const index = this.searchKeywords.findIndex(k => k.type === kw.type && k.name === kw.name)
        if (index > -1) {
          this.searchKeywords.splice(index, 1)
        }

        // 현재값
        const currVal = this[kw.type][kw.name] || ''

        // select 가 있으면 select 에서 보여줄 text 를 가져온다
        if (kw.select) {
          const sel = this.select[kw.select].find(k => k.value === currVal)
          skw.text = (sel.value) ? sel.text : ''
        } else {
          // select 가 아닌 text 입력값은 현재값을 바로 매칭한다.
          // showSelect 가 지정된 경우 해당 셀렉트의 text 를 보여준다.
          if (kw.showSelect) {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `${this.select[kw.showSelect].find(k => k.value === this.search[kw.showSelect]).text} - "${currVal}"`
            } else {
              skw.text = ''
            }
          } else {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `"${currVal}"`
            } else {
              skw.text = ''
            }
          }
        }

        if (skw.text) {
          this.searchKeywords.push(skw)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 검색어 칩을 닫는 메서드
    async closeSearchKeyword (chip) {
      try {
        if (!chip.isEver) { // isEver = true 인 넘은 없앨 수 없다. false 인 경우만 처리
          const kw = this.useSearchKeywords.find(c => c.type === chip.type && c.name === chip.name)
          if (kw.select) {
            // 셀렉트 검색인 경우
            // this.select.sido = '' 처럼 셀렉트의 가장 처음값을 초기값으로 보고 변경시킨다.
            this[kw.type][kw.name] = this.select[kw.name][0].value
            await this.selectChange(kw.name)
          } else {
            //  검색어 검색인 경우
            if (kw.type === 'search' && kw.name === 'sw') {
              this[kw.type][kw.name] = ''
              await this.searchWord()
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 리스트 제너레이터
    // 중요: /src/lib/pdfmakeList.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgen () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = '관리자 - 인사정보 목록'

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfListStyle
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '이름', style: 'tableHeader', alignment: 'center' },
          { text: '직위', style: 'tableHeader', alignment: 'center' },
          { text: '자격', style: 'tableHeader', alignment: 'center' },
          { text: '사원번호', style: 'tableHeader', alignment: 'center' },
          { text: '휴대전화', style: 'tableHeader', alignment: 'center' },
          { text: '긴급연락', style: 'tableHeader', alignment: 'center' },
          { text: '이메일', style: 'tableHeader', alignment: 'center' },
          { text: '주소', style: 'tableHeader', alignment: 'center' },
          { text: '생년월일', style: 'tableHeader', alignment: 'center' },
          { text: '취미', style: 'tableHeader', alignment: 'center' },
          { text: '상태', style: 'tableHeader', alignment: 'center' }
        ])

        this.staffs.forEach(d => {
          let bDay = ''
          if (d.birthday) {
            bDay = `${d.isLunar ? '(-)' : '(+)'}${this.$moment(d.birthday).format('YYYY.MM.DD')}`
          }
          tBody.push([
            d.name,
            d.rank,
            d.position,
            d.code,
            d.hp,
            d.emergency,
            d.email,
            d.addr,
            bDay,
            d.hobby,
            d.status ? '재직' : '퇴직'
          ])
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*', 20],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfDoc.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
